<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container find_work">
        <div class="mb-3 alert alert-warning" v-if="profile.status === 1">
          Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá enviar propostas.
        </div>
        <div class="row">
          <div class="col-lg-8">
            <h1>Últimas oportunidades</h1>
            <NewestProjects :list="this.projects" />
            <!-- <div class="circle-container mt-4 mb-4">
              <h2>
                Everything you need in one place to create and succeed in your
                career as a virtual assistant
              </h2>
              <div class="circle-content">
                <div class="card_left_row">
                  <div class="card left_1 mt-3">
                    <div class="card-body">
                      <p class="title">Comece Do Zero</p>
                      <p class="description_part">
                        Aprenda do zero como se tornar uma Assistente Virtual
                        com nossos treinamentos.
                      </p>
                    </div>
                    <span><img src="assets/img/Icons/career-icon1.svg" alt="" /></span>
                  </div>
                  <div class="card mt-3">
                    <div class="card-body">
                      <p class="title">Marketplace</p>
                      <p class="description_part">
                        Conecte-se com diversos clientes que buscam
                        profissionais como você.
                      </p>
                    </div>
                    <span><img src="assets/img/Icons/career-icon2.svg" alt="" /></span>
                  </div>
                  <div class="card left_2 mt-3">
                    <div class="card-body">
                      <p class="title">Aprendizado Constante</p>
                      <p class="description_part">
                        Aulas frequentes de diferentes temas para te ajudar na
                        evolução da sua carreira.
                      </p>
                    </div>
                    <span><img src="assets/img/Icons/career-icon3.svg" alt="" /></span>
                  </div>
                </div>

                <div class="circle">
                  <svg class="svg-inline--fa fa-user icons" aria-hidden="true" focusable="false" data-prefix="fas"
                    data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                    <path fill="currentColor"
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z">
                    </path>
                  </svg>
                </div>

                <div class="card_right_row">
                  <div class="card right_1 mt-3">
                    <span><img src="assets/img/Icons/career-icon6.svg" alt="" /></span>
                    <div class="card-body">
                      <p class="title">Comunidade</p>
                      <p class="description_part">
                        Interaja com profissionais que estão trilhando o mesmo
                        caminho que você.
                      </p>
                    </div>
                  </div>
                  <div class="card mt-3">
                    <span><img src="assets/img/Icons/career-icon4.svg" alt="" /></span>
                    <div class="card-body">
                      <p class="title">Melhore Suas Habilidades</p>
                      <p class="description_part">
                        Adquira novas habilidades e melhore os seus
                        conhecimentos através dos nossos cursos de extensão.
                      </p>
                    </div>
                  </div>
                  <div class="card right_2 mt-3">
                    <span><img src="assets/img/Icons/career-icon5.svg" alt="" /></span>
                    <div class="card-body">
                      <p class="title">Especializações</p>
                      <p class="description_part">
                        Escolha uma das nossas&lt; especializações e se destaque
                        como uma Assistente Virtual Especialista.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="col-lg-4 col-sm-12">
            <h1>Últimas propostas enviadas</h1>
            <NewestProposals :list="this.proposals" />

            <h1 class="mt-4">Últimos vídeos postados</h1>
            <NewestVideos :list="this.videos" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import NewestProjects from "../dashboard/NewestProjects.vue";
import NewestProposals from "../dashboard/NewestProposals.vue";
import NewestVideos from "../dashboard/NewestVideos.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, NewestProjects, NewestProposals, NewestVideos },

  created() {
    this.$store.commit("setActiveSection", "DashboardPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadAssistantData();
  },

  data() {
    return {
      authUser: null,
      profile: null,
      removePicture: false,
      name: null,
      picture: null,
      email: null,
      whatsapp: null,
      acceptTerms: false,
      receiveNews: false,
      projects: [],
      proposals: [],
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadAssistantData() {
      this.$store.commit("setLoading", true);

      try {
        let assistantData = await AssistantManager.getAssistantData();
        this.profile = assistantData.profile;
        this.projects = assistantData.newest_projects;
        this.proposals = assistantData.newest_proposals;
        this.videos = assistantData.newest_videos;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, null);
      }
    },
  },
};
</script>
