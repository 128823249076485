<template>
  <div class="top_navabr">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button class="navbar-toggler" id="toggleButton" onclick="window.global.toggle()">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="top-nav-content">
          <a href="javascript:void(0)" class="notification" :class="{ 'zero': notificationCount <= 0 }"
            :data-after="notificationCount" @click="showNotifications"><i class="fa-solid fa-bell icons"></i></a>
        </div>
        <div id="navbarNav" style="display: none">
          <ul class="navbar-nav nav-2">
            <li>
              <a class="nav-link" :class="{ active: this.section === 'DashboardPage' }" href="/"><i
                  class="fa-solid fa-house icons"></i>Dashboard</a>
            </li>
            <li>
              <a class="nav-link" href="/perfil" :class="{ active: this.section === 'ProfilePage' }"><i
                  class="fa-solid fa-user icons" style="padding-left: 4px; margin-right: 11px"></i>Perfil</a>
            </li>
            <li>
              <a class="nav-link" href="/propostas" :class="{
                active:
                  this.section === 'ProposalListPage' ||
                  this.section === 'ProposalPage'
              }"><i class="fa-solid fa-file-lines icons" style="padding-left: 6px; margin-right: 11px"></i>Minhas
                propostas
              </a>
            </li>
            <li>
              <a class="nav-link" href="/projetos" :class="{
                active:
                  this.section === 'ProjectListPage' ||
                  this.section === 'ProjectPage'
                  || this.section === 'ClientPage'
              }"><i class="fa-solid fa-bag-shopping icons" style="padding-left: 5px; margin-right: 11px"></i>Encontre
                trabalho</a>
            </li>
            <li>
              <a class="nav-link" href="/video-aulas" :class="{
                active:
                  this.section === 'VideoListPage' ||
                  this.section === 'VideoPage',
              }"><i class="fa-brands fa-youtube icons" style="padding-left: 2px; margin-right: 10px"></i>Video aulas
              </a>
            </li>
            <li>
              <a class="nav-link" href="/treinamentos" :class="{
                active:
                  this.section === 'TrainingListPage'
              }"><i class="fa-solid fa-group-arrows-rotate icons" style="padding-left: 3px; margin-right: 6px"></i>
                Treinamentos
              </a>
            </li>
            <li>
              <a class="nav-link" href="/ferramentas" :class="{
                active:
                  this.section === 'ToolListPage'
              }"><i class="fa-solid fa-gears icons"></i>Ferramentas
              </a>
            </li>
            <li>
              <a class="nav-link" href="/comunidade" :class="{
                active:
                  this.section === 'CommunityPage'
              }"><i class="fa-solid fa-users icons"></i>Comunidade
              </a>
            </li>
            <!-- <li>
              <a
                class="nav-link"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#support"
                ><i class="fa-solid fa-headset icons"></i>Support</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import AssistantManager from "../../js/services/assistant.js";

export default {
  data() {
    return {};
  },

  computed: {
    section() {
      return this.$store.state.activeSection;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    notificationCount() {
      return this.activeUser.last_notif_id - this.activeUser.seen_notif_id;
    }
  },

  methods: {
    async showNotifications() {
      let ctx = this;
      let $modal = $("#notificationsModal");
      $modal.find('.notification').html('<div class="loading">Carregando...</div>');
      $modal.modal('show');

      $modal.off().on('shown.bs.modal', async function () {
        try {
          let notifications = await AssistantManager.getNotifications(ctx.activeUser.last_notif_id);

          if (notifications.length > 0) {
            $modal.find('.notification').html('');
            notifications.forEach(notification => {
              let n = $(
                '<div class="item">' +
                '   <div class="sourch">' +
                '       <img src="/assets/img/notification_mail.png" alt="" />' +
                '       <div class="text">' +
                '           <p></p>' +
                '           <span class="date"></span>' +
                '       </div>' +
                '   </div>' +
                '</div>');


              if (notification.notification_type === 'signup-approved') {
                n.find('.text > p').html('Bem-vindo à Virtap!');
              } else if (notification.notification_type === 'profile-approved') {
                n.find('.text > p').html('Seu cadastro foi aprovado!');
              } else if (notification.notification_type === 'proposal-approved') {
                let data = JSON.parse(notification.notification_data);
                n.find('.text > p').html('Sua proposta para o projeto <strong data-project-title></strong> foi aprovada e agora está visível para <strong data-client-name></strong>. <a href="javascript:void(0)" target="_blank" class="link">Clique aqui para visualizá-la</a>.');
                n.find('a').attr('href', '/projeto/' + data.project_id);
                n.find('[data-project-title]').text(data.project_title);
                n.find('[data-client-name]').text(data.client_name);
              } else if (notification.notification_type === 'proposal-accepted') {
                let data = JSON.parse(notification.notification_data);
                n.find('.text > p').html('<strong data-client-name></strong> quer conversar sobre sua proposta para o projeto <strong data-project-title></strong>. <a href="javascript:void(0)" target="_blank" class="link">Clique aqui para visualizá-la</a>.');
                n.find('a').attr('href', '/projeto/' + data.project_id);
                n.find('[data-project-title]').text(data.project_title);
                n.find('[data-client-name]').text(data.client_name);
              } else if (notification.notification_type === 'invited-to-project') {
                let data = JSON.parse(notification.notification_data);
                n.find('.text > p').html('<strong data-client-name></strong> te convidou para o projeto <strong data-project-title></strong>. <a href="javascript:void(0)" target="_blank" class="link">Clique aqui para visualizá-lo</a>.');
                n.find('a').attr('href', '/projeto/' + data.project_id);
                n.find('[data-project-title]').text(data.project_title);
                n.find('[data-client-name]').text(data.client_name);

              } else if (notification.notification_type === 'project-closed') {
                let data = JSON.parse(notification.notification_data);
                n.find('.text > p').html('O projeto <strong data-project-title></strong> para o qual você enviou uma proposta foi fechado. <a href="javascript:void(0)" target="_blank" class="link">Clique aqui para visualizá-lo</a>.');
                n.find('a').attr('href', '/projeto/' + data.project_id);
                n.find('[data-project-title]').text(data.project_title);
              }
              n.find('.date').text(ctx.formatElapsedTime(notification.registration_timestamp));

              if (notification.user_notification_id > ctx.activeUser.seen_notif_id) {
                n.addClass('active');
              }

              $modal.find('.notification').append(n);
            });

          } else {
            $modal.find('.notification').html('<div class="loading">Nenhuma notificação disponível.</div>');
          }

          try {
            await AssistantManager.setLastSeenNotifications(ctx.activeUser.last_notif_id);
          } catch (err) {
            console.log(err);
          }
          ctx.activeUser.seen_notif_id = ctx.activeUser.last_notif_id;
        } catch (err) {
          console.log(err);
          $modal.find('.notification').html('<div class="loading text-danger">Erro ao carregar as notificações.<br/> Por favor, tente novamente. Se o problema persistir, atualize a página.</div>');
        }


      });

    }
  },
};
</script>
