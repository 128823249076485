<template>
  <div class="loading_container">
    <div class="alert alert-danger m-5">
      Página não encontrada
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",

  created() {
    this.$store.commit("setActiveSection", "");
    this.$store.commit("setActiveProjectList", null);
    this.$store.commit("setLoading", false);
  },

  components: {},

  data() {
    return {};
  },

  methods: {},
};
</script>
