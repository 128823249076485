<template>
  <div class="loading_container" v-if="error !== null">
    <div class="alert alert-danger m-5" v-if="error.errorCode === 'INVALID_ASSISTANT_STATUS' && error.errorData === 3">
      Seu perfil foi desativado. Entre em contato com o suporte para maiores
      informações.
    </div>

    <div class="alert alert-danger m-5" v-else-if="error.errorCode === 'PROJECT_NOT_FOUND'">
      O projeto que você tentou acessar não foi encontrado.
    </div>

    <div class="alert alert-danger m-5" v-else-if="error.errorCode === 'CLIENT_NOT_FOUND'">
      O perfil de cliente que você tentou acessar não foi encontrado.
    </div>

    <div class="alert alert-danger m-5" v-else>
      Ocorreu um erro ao processar sua requisição. Atualize a página e tente novamente.<br /><br />
      Se o problema persistir, por favor, contate o suporte via <a class="link" href="mailto:suporte@virtap.com.br"
        target="_blank">suporte@virtap.com.br</a>.
    </div>
  </div>
  <router-view v-else></router-view>
</template>

<script>
export default {
  components: {},

  computed: {
    section() {
      return this.$store.state.activeSection;
    },
    error() {
      return this.$store.state.error;
    },
  },
  data() {
    return {};
  },

  methods: {},

  watch: {
    section(newValue, oldValue) {
      let title = "Virtap | Assistente";

      switch (newValue) {
        case "DashboardPage":
          title += " | Dashboard";
          break;
        case "ProfilePage":
          title += " | Perfil";
          break;
        case "ProposalListPage":
          title += " | Minhas propostas";
          break;
        case "ProjectListPage":
          title += " | Encontre trabalho";
          break;
        case "ProjectPage":
          title += " | Projeto";
          break;
        case "ClientPage":
          title += " | Cliente";
          break;
        case "VideoListPage":
          title += " | Video aulas";
          break;
        case "VideoPage":
          title += " | Video aula";
          break;
        case "TrainingListPage":
          title += " | Treinamentos";
          break;
        case "ToolListPage":
          title += " | Ferramentas";
          break;
        case "CommunityPage":
          title += " | Comunidade";
          break;
      }
      document.title = title;
    },
  },
};
</script>
