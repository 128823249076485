<template>
  <div v-if="!projects || projects.length === 0">
    <p class="alert alert-warning mt-3">
      Nenhum projeto disponível no momento.
    </p>
  </div>
  <div class="card_group jobs" v-else>
    <div class="card mt-3" v-for="project in projects" :key="project.id">
      <div class="project_info">
        <div class="project_name">

          <div class="status">
            <a :href="'/projeto/' + project.id">
              <h2 class="break-all">{{ project.title }}</h2>
            </a>
            <p style="font-size: 13px;  margin:0; padding:0">
              Postado por <a :href="'/cliente/' + project.client_id" target="_blank"
                style="text-decoration: underline;">{{ project.name }}</a>
            </p>
            <p style="font-size: 13px; font-style: italic; margin:0; padding:0">
              {{ formatElapsedTime(new Date(project.registration_timestamp)) }}
            </p>
          </div>
          <div class="work_right_item">
            <p class="sent_active" :class="{'active' : project.assistant_id !== null}">
              Você enviou uma proposta para este projeto
            </p>
            <a :href="'/projeto/' + project.id" class="btn">Ver projeto</a>
          </div>
        </div>
        <p class="description_part mt-3 clamp-text break-all" v-html="formatMultilineText(project.description)">
        </p>
      </div>
    </div>
    <div class="text-center mt-4 mb-4">
      <a href="/projetos" class="btn see_more">Ver mais</a>
    </div> 
  </div>
</template>

<script>
import AssistantManager from "../../../js/services/assistant.js";

export default {
  props: ["list"],
  data() {
    return {
      loading: false,
      error: false,
      projects: this.list,
    };
  },
  computed: {
    projectCount() {
      if (this.projects) {
        return this.projects.length;
      }
      return 0;
    },

    activeUser() {
      return this.$store.state.activeUser;
    },
  },
  methods: {

  },
};
</script>
