<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="client-page">
          <div class="tab">
            <h1>Cliente</h1>
          </div>
          <div class="talent_profile client_profile section">
            <div class="talent-info">
              <div class="freelancer_info">
                <div class="freelancer">
                  <img class="freelancer_img" :src="getImageURL(client.picture)" v-if="client.picture" alt="" />
                  <img class="freelancer_img empty-image" v-else alt="" />
                  <div class="info">
                    <div>
                      <h2>
                        {{ client.name }}
                      </h2>
                    </div>

                    <div class="down-icons-list">
                      <div class="star">
                        <i class="fa-regular fa-star icons"></i>
                        <i class="fa-regular fa-star icons"></i>
                        <i class="fa-regular fa-star icons"></i>
                        <i class="fa-regular fa-star icons"></i>
                        <i class="fa-regular fa-star icons"></i>
                        <p class="rating">({{ client.feedback_rating }})</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="p-info">
              <div class="act-project">
                <p>
                  Cadastrado em:
                  <span>{{ formatTimestamp(client.registration_timestamp) }}</span>
                </p>
              </div>
            </div> -->
          </div>
        </div>

        <div class="tab" style="margin-top: 30px">
          <h1>Projetos publicados <span class="text-muted fs-6">({{ client.projects.length }})</span></h1>
        </div>
        <div class="section text-center" style="padding: 0; overflow: hidden">
          <div v-if="!client.projects || client.projects.length === 0">
            <h2 class="light p-5">Nenhum projeto encontrado</h2>
          </div>
          <div class="active-project no-sticky" style="padding: 0px; " v-else>

            <a class="card" style="position: static" v-for="project in client.projects" :key="project.id"
              :href="'/projeto/' + project.id">
              <div class="name">
                <p>
                  Título <br />
                  <span>{{ project.title }}</span>
                </p>
              </div>
              <div class="date">
                <p>
                  Data <br />
                  <span>{{
                    formatTimestamp(project.registration_timestamp)
                  }}</span>
                </p>
              </div>
            </a>
          </div>
        </div>

        <div class="seller_review" style="margin-top: 30px">
          <div class="tab">
            <h1>Avaliações <span class="text-muted fs-6">(0)</span></h1>
          </div>
          <div class="section text-center" style="padding:0">
            <h2 class="light p-5">Nenhuma avaliação encontrada</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ClientPage");
    this.loadClientProfile();
  },

  data() {
    return {
      client: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClientProfile() {
      this.$store.commit("setLoading", true);

      try {
        let response = await AssistantManager.getClient(
          this.$route.params.clientId
        );
        this.client = response.client;
        this.$store.commit("setActiveUser", response.assistant);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "cliente/" + this.$route.params.clientId);
      }
    },
  },
};
</script>
