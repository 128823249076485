import TimeUtils from "../utils/time.js";
import Config from "../../../resources/config.js";

const $ = window["$"];
let tooltipList = [];
let popoverList = [];

function updateWrapBoxes() {
    $(".wrap-box").each(function (index, item) {
        updateWrapBox(item);
    });
}

function updateWrapBox(item) {
    let $box = $(item);
    if ($box.is('.unclamp')) {
        return;
    }

    let p = $box.children("p").eq(0)[0];
    let $label = $box.children("label");

    if (p.scrollHeight > p.clientHeight) {
        $label.show();
    } else {
        if ($box.is('.unclamp')) {
            $label.hide();
            $box.removeClass("unclamp");
        } else {
            $label.hide();
        }
    }
}

$(document).on('click', '.wrap-box label', function () {
    let $wrapBox = $(this).closest('.wrap-box');
    $wrapBox.toggleClass("unclamp");

    if (!$wrapBox.is('.unclamp')) {
        updateWrapBox($wrapBox);
        return;
    }
});

$(window).on('resize', updateWrapBoxes);

export default {
    methods: {

        getImageURL(file) {
            return `${Config.assetsHost}/${file}`;
        },

        getVideoURL(video) {
            return `${Config.videosHost}/${video.path}.m3u8`;
        },

        getVideoThumbnailURL(video) {
            if (video.type === 1) {
                return `${Config.videosHost}/${video.path}.jpeg`;
            } else if (video.type === 2) {
                let youtube_video_id = video.path.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
                return `http://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`;
            }
        },

        formatPlural(count, singular, plural) {
            if (count === 1) {
                return count + " " + singular;
            } else {
                return count + " " + plural;
            }
        },

        formatElapsedTime(time) {

            let seconds = Math.floor((new Date() - time) / 1000);
            let intervalType;
            if (seconds === 0) {
                return 'Agora mesmo';
            }

            let interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = 'anos';
            } else {
                interval = Math.floor(seconds / 2592000);
                if (interval >= 1) {
                    if (interval > 1) {
                        intervalType = 'meses';
                    } else {
                        intervalType = 'mês';
                    }
                } else {
                    interval = Math.floor(seconds / 86400);
                    if (interval >= 1) {
                        if (interval > 1) {
                            intervalType = 'dias';
                        } else {
                            intervalType = 'dia';
                        }
                    } else {
                        interval = Math.floor(seconds / 3600);
                        if (interval >= 1) {
                            if (interval > 1) {
                                intervalType = 'horas';
                            } else {
                                intervalType = 'hora';
                            }
                        } else {
                            interval = Math.floor(seconds / 60);
                            if (interval >= 1) {
                                if (interval > 1) {
                                    intervalType = 'minutos';
                                } else {
                                    intervalType = 'minuto';
                                }
                            } else {
                                interval = seconds;
                                if (interval > 1) {
                                    intervalType = 'segundos';
                                } else {
                                    intervalType = 'segundo';
                                }
                            }
                        }
                    }
                }
            }
            return interval + ' ' + intervalType + ' atrás';
        },

        formatDuration(loanDurationInSeconds) {
            return TimeUtils.formatDuration(loanDurationInSeconds);
        },

        formatTimestamp(timestamp) {
            let date = new Date(timestamp);
            return date.toLocaleDateString("pt-BR") + " " + date.toLocaleTimeString("pt-BR")
        },

        formatMultilineText(text) {
            return $('<div/>').text(text).html().replace(new RegExp('\r?\n', 'g'), '<br />');
        },


        dateWithTimeZone(timeZone, year, month, day, hour, minute, second) {
            let date = new Date(Date.UTC(year, month, day, hour, minute, second));

            let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
            let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
            let offset = utcDate.getTime() - tzDate.getTime();

            date.setTime(date.getTime() + offset);

            return date;
        },

        formatAnnualizedReturn(loanAmount, loanInterest, loanDurationInSeconds) {
            if (!loanAmount || !loanInterest || !loanDurationInSeconds) {
                return "-";
            }

            loanAmount = Number(loanAmount);
            loanInterest = Number(loanInterest);
            loanDurationInSeconds = Number(loanDurationInSeconds);

            let loanDays = loanDurationInSeconds / (24 * 3600);
            let totalReturn = (loanAmount + loanInterest) / loanAmount;
            let arr = Math.pow(totalReturn, (365 / loanDays)) - 1;

            return parseFloat(arr * 100).toFixed(4) + "%";
        },

        formatTotalAmount(loan) {
            if (!loan || !loan.amount || !loan.interest) {
                return "-";
            }

            return this.formatPrice(BigInt(loan.amount) + BigInt(loan.interest));
        },

        formatNumber(number) {
            return intlTelInputUtils.formatNumber(number, null, intlTelInputUtils.numberFormat.INTERNATIONAL)
        },

        buildSessionLink() {
            return "Sua sessão expirou.<br/><a onclick='window.global.popupWindow(\"" + Config.apiHost + "/auth/google/assistant?popup=true\",\"_blank\",window, 600, 400)' target='_blank' class='link'> Clique aqui para entrar novamente</a>";
        },

        formatClientStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Inativo";
                default:
                    return "Desconhecido";
            }
        },

        formatProjectStatus(status) {
            switch (status) {
                case 1:
                    return "Em análise";
                case 2:
                    return "Ativo";
                case 3:
                    return "Desativado";
                case 4:
                    return "Fechado";
                default:
                    return "Desconhecido";
            }
        },

        formatProposalStatus(proposal) {
            if (proposal.status === 1) { // under review
                if (proposal.project_status === 3) { // inactive
                    return "Proposta desativada";
                } else if (proposal.project_status === 4) { // closed
                    if (!proposal.released) {
                        return "Proposta recusada";
                    } else {
                        return "Cliente interessado";
                    }
                } else { // under review, active
                    return "Proposta enviada";
                }
            } else if (proposal.status === 2) { //active
                if (proposal.project_status === 3) { // inactive
                    return "Proposta desativada";
                } else if (proposal.project_status === 4) { // closed
                    if (!proposal.released) {
                        return "Proposta recusada";
                    } else {
                        return "Cliente interessado";
                    }
                } else { // under review, active
                    if (proposal.released) {
                        return "Cliente interessado";
                    } else {
                        return "Proposta enviada";
                    }
                }
            }
            else if (proposal.status === 3) { // inactive
                return "Proposta desativada";
            } else {
                return "Desconhecido";
            }

        },


        getProposalTextClass(proposal) {
            if (proposal.status === 1) { // under review
                if (proposal.project_status === 3) { // inactive
                    return "text-danger";
                } else if (proposal.project_status === 4) { // closed
                    return "text-orange";
                } else { // under review, active
                    return "text-warning";
                }
            } else if (proposal.status === 2) { //active
                if (proposal.project_status === 3) { // inactive
                    return "text-danger";
                } else if (proposal.project_status === 4) { // closed
                    if (!proposal.released) {
                        return "text-orange";
                    } else {
                        return "text-success";
                    }
                } else { // under review, active
                    if (proposal.released) {
                        return "text-success";
                    } else {
                        return "text-warning";
                    }
                }
            }
            else if (proposal.status === 3) { // inactive
                return "text-danger";
            } else {
                return "text-dark";
            }
        },

        getProposalAcceptanceClass(accepted) {
            if (accepted) {
                return "text-success";
            } else {
                return "text-warning";
            }
        },

        getProposalSentClass(sent) {
            if (sent) {
                return "text-success";
            } else {
                return "text-danger";
            }
        },

        getBtnStatusClass(status) {
            switch (status) {
                case 1:
                    return "bg-warning";
                case 2:
                    return "bg-success";
                case 3:
                    return "bg-danger";
                case 4:
                    return "bg-orange";
                default:
                    return "bg-dark";
            }
        },

        getTextStatusClass(status) {
            switch (status) {
                case 1:
                    return "text-warning";
                case 2:
                    return "text-success";
                case 3:
                    return "text-danger";
                case 4:
                    return "text-orange";
                default:
                    return "text-dark";
            }
        },

        handleAPIError(error, sourcePage) {
            console.log(error);
            if (error.status === 401) {
                window.location = Config.loginPage + (sourcePage ? "?next=" + sourcePage : "");
            } else if (error.errorCode === "ASSISTANT_NOT_FOUND") {
                window.location = Config.signupPage + "?nome=" + error.errorData;
            } else {
                this.$store.commit("setError", error);
                this.$store.commit("setLoading", false);
            }
        },

        showError(err) {
            console.log(err);
            let error;
            if (err.data && err.data.data && err.data.data.message) {
                error = err.data.data.message;
            } else if (err.message) {
                error = err.message;
            } else {
                error = err;
            }

            this.$store.commit("setLoading", false);

            $("#errorMsg").html(this.formatMultilineText(error));
            $("#errorModal").modal("show");

        },

        showSuccess(msg) {
            $("#successMsg").html(this.formatMultilineText(msg));
            $("#successModal").modal("show");
        },

        showConfirmation(title, confirmationMessage, confirmButtonTitle, confirmAction, successMessage, errorMessage) {
            let $confirmModal = $("#confirmModal");
            $confirmModal.find("[data-title]").text(title);
            $confirmModal.find("[data-msg]").text(confirmationMessage);

            this.showModal($confirmModal, function () { }, confirmButtonTitle, confirmAction, successMessage, errorMessage);
        },

        showModal(modal, init, confirmButtonTitle, confirmAction, successMessage, errorMessage, closeAction) {
            let $ctx = this;
            let $modal = $(modal);
            let $modalError = $modal.find('[data-error]')
            let $btnConfirmAction = $modal.find('[data-confirm]');

            $modalError.hide();
            $btnConfirmAction.text(confirmButtonTitle);

            $modal.data('initFunction', function () {
                $('html').addClass('noscroll');
                init($modal);
            });

            $modal.off().on('hidden.bs.modal', function () {
                $('html').removeClass('noscroll');
                $modal.data('initFunction', 'null');
                if (closeAction) {
                    closeAction();
                }
            });

            $btnConfirmAction.off().on("click", async function () {
                try {
                    $modal
                        .find("input, textarea, button, .btn")
                        .addClass("disabled")
                        .prop("disabled", true);

                    $btnConfirmAction.text("Por favor, aguarde...");
                    $modalError.hide();

                    if (await confirmAction()) {
                        $modal.modal("hide");
                        $ctx.showSuccess(successMessage);
                    }
                } catch (error) {
                    console.log(error);
                    if (error.status === 401) {
                        $modalError.html($ctx.buildSessionLink()).show();
                    }
                    else if (error.errorCode === "INVALID_ASSISTANT_STATUS" && error.errorData === 3) {
                        $modalError.html("Seu perfil foi desativado.<br/>Entre em contato com o suporte para maiores informações.").show();
                    }
                    else if (error.errorCode === "MONTHLY_PROPOSALS_EXCEEDED") {
                        $modalError.html("Você excedeu o limite de " + error.errorData.limit + " propostas<br/<br/>Aguarde até o próximo mês ou assine a Virtap para ter acesso mais propostas.").show();
                    }
                    else if (error.errorCode === "PROJECT_NOT_FOUND") {
                        $modalError.html("O projeto não está mais disponível.").show();
                    } else {
                        $modalError.html($ctx.formatMultilineText(errorMessage)).show();
                    }
                } finally {
                    $modal
                        .find("input, textarea, button, .btn")
                        .removeClass("disabled")
                        .prop("disabled", false);

                    $btnConfirmAction.text(confirmButtonTitle);
                }
            });

            $modal.modal("show");

        },

        hasContactInfo(text) {
            let emailPat = /(\".*\"|[A-Za-z]\w*)@(\[\d{1,3}(\.\d{1,3}){3}]|[A-Za-z]\w*(\.[A-Za-z]\w*)+)/g
            let phonePart = /[0-9\s\-\()]+/g;
            let urlPat = "[A-Za-z]+://[A-Za-z0-9-_]+\\.[A-Za-z0-9-_%&\?\/.=]+";
            if (text.match(emailPat)) {
                return true;
            }

            let words = text.split(" ");
            if (words) {

                for (let word of words) {
                    if (word.replace(/\D/g, "").length >= 8) {
                        return true;
                    }
                }
            }

            let m = text.match(phonePart);
            if (m) {
                for (let word of m) {
                    if (word.replace(/\D/g, "").length >= 8) {
                        return true;
                    }
                }
            }

            if (text.match(urlPat)) {
                return true;
            }

            return false;
        },

        getCervAVTitle() {
            return 'Profissional certificado em <br/>Assistência Virtual';
        },

        getCertAVDescription() {
            return 'Este é um treinamento introdutório em Assistência Virtual fornecido pela Virtap.<br/><br/> Elaborado por profissionais que acumulam 10 anos de experiência no mercado.';
        },

        getCervAPTitle() {
            return 'Profissional certificado em <br/> Assessoria Pessoal';
        },

        getCertAPDescription() {
            return 'Fornecido pela Virtap, este é o treinamento mais completo em Assistência Pessoal do Brasil.<br/><br/> Elaborado por profissionais que acumulam 10 anos de experiência no mercado.';
        },

        initTooltips() {
            tooltipList.forEach((tooltip) => { tooltip.dispose() });
            let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
            tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new bootstrap.Tooltip(tooltipTriggerEl)
            });

            popoverList.forEach((popover) => { popover.dispose() });
            let popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
            popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {

                popperConfig(defaultBsPopperConfig) {
                    const newPopperConfig = { strategy: 'fixed' }
                    defaultBsPopperConfig.modifiers[0].enabled = false;

                    // use defaultBsPopperConfig if needed...
                    return defaultBsPopperConfig
                }
            }))
        },

        applyTruncation(callback) {
            this.$nextTick(function () {
                setTimeout(updateWrapBoxes, 400);
                if (callback) {
                    setTimeout(callback, 400);
                }
            });;

        }
    }
}