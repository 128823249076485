<template>
  <div class="tab mt-4">
    <h1>Sua proposta para este projeto</h1>
  </div>
  <div class="talent_profile proposallls section">
    <div class="alert alert-danger" style="margin-bottom: 25px" v-if="client.status !== 2 || project.status === 3">
      Este projeto foi desativado da plataforma e sua proposta não poderá ser analisada pelo cliente.
    </div>
    <div class="alert alert-danger" style="margin-bottom: 25px" v-else-if="proposal.status == 3">
      Sua proposta está desativada. Entre em contato com o suporte para maiores informações.
    </div>
    <div class="alert alert-warning" style="margin-bottom: 25px" v-else-if="project.status === 4 && !proposal.released">
      Sua proposta não foi aceita pelo cliente.
    </div>
    <div class="alert alert-warning" style="margin-bottom: 25px" v-else-if="project.status === 4 && proposal.released">
      <strong>{{ client.name }}</strong> iniciou o contato com você utilizando o número abaixo.
    </div>
    <div class="alert alert-warning" style="margin-bottom: 25px" v-else-if="proposal.status == 1">
      Sua proposta está sendo analisada pela nossa equipe. Assim que for aprovada, ela ficará visível para o cliente.
    </div>
    <div class="alert alert-warning" style="margin-bottom: 25px" v-else-if="proposal.status == 2 && proposal.released">
      <strong>{{ client.name }}</strong> quer conversar com você sobre sua proposta. Entre em contato pelo número abaixo.
    </div>
    <div class="talent-info">
      <div class="freelancer_info">
        <div class="freelancer">

          <a :href="'/perfil'">

            <img class="freelancer_img" :src="getImageURL(assistant.picture)" v-if="assistant.picture" alt="" />
            <img class="freelancer_img empty-image" v-else alt="" />

          </a>
          <div class="info">
            <div>
              <a target="_blank" :href="'/perfil'">
                <h2 class="break-word">
                  {{ assistant.name }}
                </h2>
              </a>
              <p class="break-word title">{{ assistant.title }}</p>
            </div>
            <div class="down-icons-list">

              <div class="ex_icon" v-if="assistant.cert_av || assistant.cert_ap">
                <span tabindex="0" data-bs-placement='bottom' data-bs-toggle="popover" :data-bs-title="getCervAVTitle()"
                  data-bs-custom-class="certav-popover" :data-bs-content="getCertAVDescription()" data-bs-html="true"
                  v-if="assistant.cert_av" class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>

                <span tabindex="0" data-bs-placement="bottom" data-bs-toggle="popover" :data-bs-title="getCervAPTitle()"
                  :data-bs-content="getCertAPDescription()" data-bs-html="true" data-bs-custom-class="certap-popover"
                  v-if="assistant.cert_ap" class="cert_ap"><i class="fa-solid fa-star icons"></i></span>
                <!-- <span><i class="fa-solid fa-trophy icons"></i></span> -->
              </div>


              <div class="star">
                <i class="fa-regular fa-star icons"></i>
                <i class="fa-regular fa-star icons"></i>
                <i class="fa-regular fa-star icons"></i>
                <i class="fa-regular fa-star icons"></i>
                <i class="fa-regular fa-star icons"></i>
                <span class="rating"> ({{ assistant.feedback_rating }})</span>
              </div>
            </div>
            <p class="country">{{ assistant.location }}</p>
          </div>
        </div>
        <span class="btn bg-danger mx-2" v-if="client.status !== 2 || project.status === 3">Proposta desativada</span>
        <span class="btn bg-danger mx-2" v-else-if="proposal.status === 3">Proposta desativada</span>
        <span class="btn bg-orange mx-2" v-else-if="project.status === 4 && !proposal.released">Proposta recusada</span>
        <a :href="formatWPLink(client.contact_info.whatsapp)" target="_blank" class="btn mx-2"
          v-else-if="project.status === 4 && proposal.released"><img src="/assets/img/logos_whatsapp-icon.svg" />
          {{ formatNumber(client.contact_info.whatsapp) }}</a>
        <span class="btn bg-warning mx-2" v-else-if="proposal.status === 1">Proposta enviada</span>
        <a :href="formatWPLink(client.contact_info.whatsapp)" target="_blank" class="btn mx-2"
          v-else-if="proposal.status === 2 && proposal.released"><img src="/assets/img/logos_whatsapp-icon.svg" />
          {{ formatNumber(client.contact_info.whatsapp) }}</a>
        <span class="btn bg-warning mx-2" v-else-if="proposal.status === 2 && !proposal.released">Proposta enviada</span>
      </div>
    </div>

    <div class="keywords-group">
      <div class="ky_btn" v-if="assistant.area_ap">
        Assistência Pessoal
      </div>
      <div class="ky_btn" v-if="assistant.area_ae">
        Secretariado Executivo
      </div>
      <div class="ky_btn" v-if="assistant.area_sa">
        Suporte Administrativo
      </div>
      <div class="ky_btn" v-if="assistant.area_sr">
        Secretariado remoto
      </div>
      <div class="ky_btn" v-if="assistant.area_si">
        Suporte para Infoprodutores
      </div>
      <div class="ky_btn" v-if="assistant.area_ed">
        Entrada de dados
      </div>

    </div>


    <!-- <hr class="mt-5" /> -->
    <div class="description_part">
      <p class="mt-4 break-word" style="font-size: 14.2px" v-html="formatMultilineText(proposal.proposal)"></p>
    </div>
  </div>
</template>
  
<script>


export default {
  props: ["assistant", "client", "project", "proposal"],
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    formatWPLink(number) {
      return 'https://wa.me/' + String(number).replace(/\s/g, "").replace(/-/g, "");;
    }
  },
};
</script>
  