<template>
  <div class="post_project user mt-3" style="height:auto;">
    <div class="proposals-list" :class="{ 'pb-0': hasProposals }" style="height: auto;max-height: 100%">
      <div class="proposal-container" :class="{ nopro: !hasProposals }">
        <div class="no_proposal" v-if="!hasProposals">
          <img src="/assets/img/proposal.svg" width="100" />
          <h2 class="light">Nenhuma proposta</h2>
        </div>
        <a :href="'/projeto/' + proposal.project_id" v-else v-for="proposal in proposals" :key="proposal.id">
          <div class="proposal">
            <div class="user">
              <div class="freelancer-img">
                <div class="active-box active"></div>
                <img src='assets/img/proposal.svg' />

              </div>
              <div class="proposal-info">
                <p class="name ellipsis" v-html="formatMultilineText(proposal.title)"></p>
                <p class="project ellipsis" v-html="formatMultilineText(proposal.proposal)"></p>
                <p class="date ellipsis" v-html="formatMultilineText(formatElapsedTime(proposal.last_update_timestamp))"></p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div v-if="hasProposals" class="mt-3" style="text-align: center">
    <a class="btn_s" href="/propostas">Ver todas <i class="fa-solid fa-arrow-right-long"></i></a>
  </div>
</template>

<script>
export default {
  props: ["list"],
  data() {
    return {
      proposals: this.list,
    };
  },
  computed: {
    hasProposals() {
      return this.proposals && this.proposals.length > 0;
    },
  },
  methods: {

  },
};
</script>
