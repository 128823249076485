<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="element">
          <div class="search-box-container">
            <div class="nav">
              <div>
                <h2>Video aulas</h2>
                <p> Aulas frequentes para te ajudar na evolução da sua carreira</p>
              </div>
              <div class="search-box">
                <input type="text" id="searchVideoInput" placeholder="Buscar aulas" @input="debounceSearch"
                  :class="{ disabled: searching || loadingMore }" :disabled="searching || loadingMore" />
                <button type="submit" @click="searchVideos()" :class="{ disabled: searching || loadingMore }"
                  :disabled="searching || loadingMore">
                  <i class="fa fa-search icons"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="talent-page" v-if="searching">
            <div class="loading_container" style="height: auto; margin-top: 30px; overflow: hidden">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
          <div class="talent-page" v-else-if="searchError">
            <p class="mt-3 text-center text-danger" style="font-weight: bold">
              Ocorreu um erro ao buscar videos.<br /> Por favor, atualize a
              página e tente novamente.
            </p>
          </div>

          <div class="talent-page" v-else-if="videos && videos.length === 0">
            <p class="mt-3 text-center" style="font-weight: bold" v-if="hasKeywords">
              Nenhum video encontrado. Tente buscar com outros termos.
            </p>
            <p class="mt-3 text-center" style="font-weight: bold" v-else>
              Nenhum video disponível ainda. Volte em breve.
            </p>
          </div>
          <div class="talent-page mt-3" v-else>
            <div class="video_class">
              <div class="row gy-3 mt-0" v-for="grid in videoGrid">
                <div class="col-md-6 col-lg-3 video" v-for="video in grid" :key="video.id" :id="video.id">
                  <a :href="'/video/' + video.id">
                    <div class="card">
                      <div class="video_img_container">
                        <img class="video_img" :src="getVideoThumbnailURL(video)" alt="">
                      </div>
                      <div class="card-body">
                        <h4>{{ video.title }}</h4>
                        <p>{{ formatElapsedTime(video.registration_timestamp) }}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="page_number">
                <button id="fetchMoreVideos" class="btn see_more btn-small" :class="{ disabled: loadingMore }"
                  @click="fetchMore()" v-if="hasMore">
                  {{ loadingMore ? 'Carregando...' : 'Mostrar mais' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 10;

import debounce from "lodash.debounce";

const debouncedHandler = debounce((event, func) => {
  func(event.target.value);
}, 500);

export default {
  components: { SideBar, TopBar },

  beforeUnmount() {
    debouncedHandler.cancel();
  },

  created() {
    this.$store.commit("setActiveSection", "VideoListPage");
    this.loadVideos();
  },

  data() {
    return {
      searching: false,
      profile: null,
      videos: [],
      hasKeywords: false,
      searchError: false,
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    videoGrid() {
      var newArr = [];

      for (var i = 0; i < this.videos.length; i += 4) {
        newArr.push(this.videos.slice(i, i + 4));
      }
      return newArr;
    }
  },

  methods: {
    async loadVideos() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.searchVideos(null, true, null, RESULT_COUNT);
        this.videos = response.videos;
        this.$store.commit("setActiveUser", response.profile);
        this.$store.commit("setLoading", false);
        this.hasMore = this.videos.length >= RESULT_COUNT;
      } catch (error) {
        this.handleAPIError(error, "video-aulas");
      }
    },

    debounceSearch(e) {
      debouncedHandler(e, () => {
        this.searchVideos();
      });
    },

    async searchVideos() {
      let ctx = this;
      ctx.searching = true;
      ctx.searchError = false;
      try {
        let response = await AssistantManager.searchVideos(
          $("#searchVideoInput").val(),
          false,
          null,
          RESULT_COUNT
        );

        this.videos = response.videos;
        this.hasMore = response.videos.length >= RESULT_COUNT;
        this.hasKeywords = !StringUtils.isEmpty($("#searchVideoInput").val());
      } catch (err) {
        console.log(err);
        ctx.searchError = true;
      } finally {
        ctx.searching = false;
        ctx.$nextTick(() => {
          $("#searchVideoInput").focus();
          ctx.applyTruncation();
        });
      }
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      ctx.searchError = false;
      let lastVideo = null;
      if (this.videos && this.videos.length > 0) {
        lastVideo = this.videos[this.videos.length - 1];
      }
      try {
        let response = await AssistantManager.searchVideos(
          $("#searchVideoInput").val(),
          false,
          lastVideo,
          RESULT_COUNT
        );

        this.videos = this.videos.concat(response.videos);
        if (response.videos.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          ctx.applyTruncation(() => {

            if (response.videos.length > 0) {
              let card = $(".talent-page").find('#' + response.videos[0].id).get(0);
              let navBar = $(".body-container .element .nav").get(0);
              let scrollDiv = card.offsetTop - navBar.offsetHeight - 116;
              window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
            }
          });
        });

      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },
  },
};
</script>
